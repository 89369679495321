.sidebar {
  @apply h-[calc(100dvh-60px)] z-40 flex flex-col transition-all ease-out duration-300 text-white relative;
}

.sidebar .container {
  @apply w-full h-full min-w-full;
}
.sidebar .nav {
  @apply p-0;
}
.sidebar .nav li {
  @apply py-1 px-2 w-full;
}

.sidebar .nav li .link {
  @apply text-white flex items-center justify-between w-full transition-all overflow-hidden py-1 px-1 lg:px-2 rounded-md;
}
.sidebar .nav li .link .subarrow {
  @apply transition-all rotate-0;
}

.sidebar .nav li .link:hover {
  @apply lg:bg-white lg:bg-opacity-5;
}

.sidebar .nav li .link.active {
  @apply bg-white bg-opacity-10;
}
.sidebar .nav li:hover > .subarrow,
.sidebar .nav li.active > .subarrow {
  @apply rotate-90;
}

.sidebar .nav li .icon {
  @apply min-w-[20px] min-h-[20px] w-[20px] h-[20px] mr-3 text-menu-300;
}

.sidebar .nav li .navlabel {
  @apply text-[14px] text-nowrap text-ellipsis;
}

.sidebar .nav li:hover > ul.submenu,
.sidebar .nav li.open > ul.submenu {
  @apply block;
}

.sidebar .nav li.open > span > svg {
  @apply rotate-90;
}
