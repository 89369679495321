@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ttip {
  opacity: 1;
  transition: all;
  padding: 4px 8px;
  background-color: rgb(60, 60, 60);
  color: white;
  border-radius: 6px;
  font-size: 11px;
}
.ttip .ttippoint {
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  top: auto;
  bottom: -5px;
  left: calc(50% - 5px);
  transform: rotate(0deg);
}

.ttip.popbottom .ttippoint {
  top: -5px;
  bottom: auto;
  transform: rotate(180deg);
}

.ttip.popleft .ttippoint {
  top: calc(50% - 3px);
  bottom: auto;
  left: calc(100% - 3px);
  transform: rotate(-90deg);
}

.ttip.popright .ttippoint {
  top: calc(50% - 3px);
  bottom: auto;
  left: -3px;
  transform: rotate(-90deg);
}

.ttip.hidettip {
  opacity: 0;
  z-index: -1;
}

.ttip.showttip {
  animation: fadeIn 500ms ease-out;
}
