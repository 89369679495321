/* main app colors */
.signin-bg {
  @apply bg-gradient-to-r from-cyan-500 to-blue-500 w-full h-[100dvh] xl:py-24 lg:py-12 p-4;
}

.header-bg {
  @apply bg-menu-800;
}

.sidebar-bg {
  @apply bg-menu-700;
}

.viewbar-bg {
  @apply bg-menu-200;
}

/* main opaque background */
.opaque-bg {
  @apply w-[100vw] h-[100dvh] fixed top-0 left-0 bg-black opacity-50 z-[990];
}

/* main btn */
.btn {
  @apply rounded-md text-black p-2 text-sm flex items-center justify-center hover:bg-gray-400;
}

.btn:not(:first-child) {
  @apply ml-2;
}

.btn.warning {
  @apply bg-yellow-500 hover:bg-yellow-600;
}

.btn.danger {
  @apply bg-red-500 hover:bg-red-600 text-white;
}

.btn.success {
  @apply bg-green-500 hover:bg-green-600 text-white;
}

.btn.primary {
  @apply bg-primary-500 hover:bg-primary-600 text-white;
}

.btn.secondary {
  @apply bg-secondary-500 hover:bg-secondary-600 text-white;
}
.btn.grayed {
  @apply bg-gray-500 hover:bg-gray-600 text-white;
}
.btn.selection {
  @apply bg-violet-500 hover:bg-violet-600 text-white;
}

.btn.print {
  @apply bg-orange-600 text-white hover:bg-orange-800;
}

.btn:disabled {
  @apply bg-gray-400 hover:bg-gray-400;
}

.btn.pop-btn > span {
  display: inline-block !important;
}

.btn.new,
.btn.primary {
  @apply bg-primary-500 hover:bg-primary-600 text-white;
}

.btn.new.pop-btn,
.btn.primary.pop-btn {
  @apply text-primary-500;
}

.btn.secondary {
  @apply bg-secondary-500 hover:bg-secondary-600 text-white;
}

.btn.secondary.pop-btn {
  @apply text-secondary-500;
}

.btn.success {
  @apply bg-green-500 hover:bg-green-600 text-white;
}

.btn.success.pop-btn {
  @apply text-green-500;
}

.btn.grayed {
  @apply bg-gray-500 hover:bg-gray-600 text-white;
}
.btn.grayed.pop-btn {
  @apply text-gray-500;
}

.btn.selection {
  @apply bg-violet-500 hover:bg-violet-600 text-white;
}
.btn.selection.pop-btn {
  @apply text-violet-500;
}

.btn.filter,
.btn.search {
  @apply bg-teal-500 hover:bg-teal-600 text-white;
}
.btn.filter.pop-btn,
.btn.search.pop-btn {
  @apply text-teal-500;
}

.btn.delete {
  @apply bg-red-500 hover:bg-red-600 text-white;
}

.btn.delete.pop-btn {
  @apply text-red-500;
}

.btn.delete.tableaction,
.btn.delete.transparant {
  @apply bg-transparent text-red-500 hover:text-red-600 hover:bg-gray-100;
}

.btn.print {
  @apply bg-orange-600 text-white hover:bg-orange-800;
}
.btn.print.pop-btn {
  @apply text-orange-500;
}

.btn.print.tableaction,
.btn.print.transparant {
  @apply bg-transparent text-orange-500 hover:text-orange-600 hover:bg-gray-100;
}

.btn svg {
  @apply w-[18px] h-[18px];
}

.btn.stacked {
  @apply ml-0 mb-1 last:mb-0;
}

.btn.blocked {
  @apply rounded-none;
}

.btn.transparant {
  @apply bg-transparent text-black hover:bg-gray-100;
}

.btn.stacked.blocked {
  @apply border-b-[1px] mb-0;
}

.btn.fullw {
  @apply w-full;
}

.btn.tableaction {
  @apply p-0 bg-transparent text-black inline-block;
}

.btn.popper-action {
  @apply mb-0 ml-0 rounded-none shadow-none min-w-full border-b-[1px];
}

.btn.popper-action:last-child {
  @apply border-b-0;
}

.btn.nomlabel > span {
  @apply hidden lg:inline-block;
}

/* main popup group */
.popper {
  @apply relative hover:cursor-pointer;
}

.popper .pop {
  @apply hidden absolute right-0 top-0 bg-white p-0 rounded-lg flex-col items-start z-[999] overflow-hidden;
}

/* labels */
.label {
  @apply p-1 pt-0 pb-0 bg-gray-400 rounded-sm text-white;
}

.label .label.danger {
  @apply bg-red-500;
}

.label.info {
  @apply bg-blue-700;
}

.label.inverse {
  @apply bg-slate-900;
}

.label.success {
  @apply bg-green-500;
}
.label.warning {
  @apply bg-yellow-500;
}

/*apptoast*/

.apptoastpositioner {
  @apply absolute top-0 left-0 flex justify-end min-w-full;
}
.apptoastpositioner.middle {
  @apply justify-center;
}

.apptoastpositioner.left {
  @apply justify-start;
}

.apptoastpositioner.right {
  @apply justify-end;
}

.apptoastholder {
  @apply z-[9999] flex flex-col items-end mt-4;
}

.apptoastpositioner.middle .apptoastholder {
  @apply items-center;
}

.apptoastholder .apptoast {
  @apply bg-white p-2 xl:p-4 text-xs xl:text-sm text-black border-l-8 rounded-md mb-2 shadow-xl max-w-[90vw];
}

.apptoastholder .apptoast.error {
  @apply border-red-800 bg-red-500 text-white;
}

.apptoastholder .apptoast.success {
  @apply border-green-800 bg-green-500 text-white;
}

.apptoastholder .apptoast.info {
  @apply border-blue-800 bg-blue-500 text-white;
}

.apptoastholder .apptoast.in {
  @apply animate-enter;
}

.apptoastholder .apptoast.out {
  @apply animate-leave;
}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and
  (-webkit-appearance: none) {
  @media only screen and (max-width: 768px) {
    input[type='date'] {
      appearance: none !important;
    }

    input[type='date']:after {
      color: gray;
      content: attr(placeholder) !important;
      text-wrap: nowrap;
      font-size: 14px;
    }

    input[type='date']:not([value='']):after {
      content: '' !important;
    }
  }
}
